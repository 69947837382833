import { STT_OK, STT_UNAUTHORIZED } from "src/const/app.const";
import { createApi } from ".";
import toast from 'react-hot-toast';
import { Message } from "src/types/models";

/**
 * Without businessId by default is users message box
 */
export const getMessagesBox = async (data: {
  businessId?: string;
  limit: number;
  offset: number;
}) => {
  const res = await createApi().get<{
    results: Message[];
    total: number;
    newMessages: number;
    message?: string;
  }>("/messages/box", data);

  // Expire token scenario. The page will be reloaded. Don't show the error
  if (res.status === STT_UNAUTHORIZED) {
    return { results: [], total: 0, newMessages: 0 };
  }
  if (res.status !== STT_OK || !res.data) {
    toast.error(`${res.data?.message || res.originalError}`);
    return { results: [], total: 0, newMessages: 0 };
  }
  return res.data;
};

export const getConversation = async (data: {
  senderId: string;
  limit: number;
  offset: number;
}) => {
  const res = await createApi().get<{
    results: Message[];
    total: number;
    message?: string;
  }>("/messages/list", data);

  if (res.status !== STT_OK || !res.data) {
    toast.error(`${res.data?.message || res.originalError}`);
    return { results: [], total: 0 };
  }
  return res.data;
};

export const readConversation = async (data: {
  senderId: string;
  businessId?: string;
}) => {
  const res = await createApi().post<{
    ok: boolean;
    count: number;
    message?: string;
  }>("/messages/read", data);

  if (res.status !== STT_OK) {
    toast.error(`${res.data?.message || res.originalError}`);
    return false;
  }
  return true;
};
