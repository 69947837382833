// RewardMap.js

import React from "react";
import Image from "next/image";
import Link from "next/link";

const RewardMap = ({ isLogged, welcomeBonus, activeInCommunity, setShowLogin }) => {
    return (
        <div className="flex w-full flex-col p-6 h-full">
            {isLogged ? (
                <div className="flex flex-col w-full h-full">
                    {!welcomeBonus ? (
                        <div className="flex flex-row items-start mt-2">
                            <Image
                                src="/Arrow_icon.svg"
                                alt="Arrow"
                                width={24}
                                height={20}
                                className="w-5 h-5"
                                style={{ width: 24, height: 20 }}
                            />
                            <div className="ml-2">
                                <p className="font-bold">Unlock up to <span style={{ color: "#EAAC00" }}>$200 </span> in MIKI points</p>
                                <p className="mt-1/2" style={{ color: "#9B9A9A" }}>Make a first purchase to unlock your welcome bonus</p>
                            </div>
                        </div>
                    ) : null}
                    {!activeInCommunity ? (
                        <div className="flex flex-row items-start mt-2">
                            <Image
                                src="/Arrow_icon.svg"
                                alt="Arrow"
                                width={24}
                                height={20}
                                className="w-5 h-5"
                                style={{ width: 24, height: 20 }}
                            />
                            <div className="ml-2">
                                <p className="font-bold">Unlock <span style={{ color: "#EAAC00" }}>community rewards</span> and increase cash back up to<span style={{ color: "#EAAC00" }}> 3%</span></p>
                                <p className="mt-1/2" style={{ color: "#9B9A9A" }}>Redeem a first opportunity within your community to activate your community</p>
                            </div>
                        </div>
                    ) : null}
                    <div className="flex flex-row items-start mt-2">
                        <Image
                            src="/Arrow_icon.svg"
                            alt="Arrow"
                            width={24}
                            height={20}
                            className="w-5 h-5"
                            style={{ width: 24, height: 20 }}
                        />
                        <div className="ml-2">
                            <p className="font-bold">Unlock up to <span style={{ color: "#EAAC00" }}>$200 </span> in MIKI points per referral</p>
                            <p className="mt-1/2" style={{ color: "#9B9A9A" }} >Share your <Link href="/rewards/referrals" className="underline">referral link</Link> and earn more rewards when your friends sign up</p>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="flex flex-col text-lg">
                    <p>Your welcome bonus awaits.</p>
                    <p className="text-base mt-3">Unlock up to <span style={{ color: "#EAAC00" }} className="font-semibold">$200 </span> in MIKI points.</p>
                    <div className="mt-3 items-center justify-center" >
                        <button className="w-full max-w-[400px] rounded-md border brand-color text-white py-2 px-2" onClick={() => setShowLogin(true)}>
                            Sign up
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default RewardMap;
