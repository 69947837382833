
import {
    STT_OK,
    STT_NOT_FOUND,
  } from "src/const/app.const";
  import { createApi } from "src/services/index";
  import { BusinessPublic } from "src/types/models";
  import toast from 'react-hot-toast';
  
  export const getBusinessInfo = async (id: string): Promise<false | BusinessPublic> => {
    try {
      const response = await createApi().get<{
        result: BusinessPublic;
        message?: string;
      }>(`/business-info/${id}`);
  
      if (response.status === STT_NOT_FOUND) {
        // Error silent - when we delete an event it happens somehow
        return false;
      }
  
      if (response.status !== STT_OK || !response.data) {
        toast.error(
          (response as { message?: string })?.message || "Something went wrong."
        );
        return false;
      }
  
      return response.data?.result as BusinessPublic;
    } catch (error) {
      console.error('Error fetching business info:', error);
      return false;
    }
  };
  