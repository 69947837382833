import { DealLocationFilter } from "./Filters";
import SearchBar from "./Filters/SearchBar";
import WalletPopup from "./Filters/WalletPopup";
import { useRouter } from "next/router";
interface MobileNavProps {
  title: string;
}

export default function MobileNav({ title }: MobileNavProps) {
  const router = useRouter();
  const showFilters = router.route === "/";

  const hideNavPaths = ["/community", "/account", "/messages", "/opportunities"];
  const shouldHideNav = hideNavPaths.includes(router.pathname) || router.pathname.startsWith("/merchant");

  return (
    <>
    {!shouldHideNav && (
      <nav className={`navbar  border border-b bg-gray-50  ${showFilters ? "py-0" :"py-1"}`}>
      <div className="mx-auto  max-w-7xl">
                  <div className="relative flex h-16 items-center justify-between">
                  <div className="flex flex-1 items-center justify-around">
                    {title ? <h1 className="text-2xl font-semibold">{title}</h1> : null}
                    {!title && showFilters ? (
                      <div className="flex grow justify-center items-center sm:gap-x-4" style={{ padding: '0 28px' }}>
                          <SearchBar/>
                        <div className="flex justify-center items-center ml-2 sm:ml-4">
                          <DealLocationFilter/>
                          <WalletPopup />
                        </div>
                      </div>
                    ) : 
                    null}
                  </div>
                </div>
      </div>
    </nav>
    )}
    </>
  );
}
