import React, { useState, useEffect, useRef } from "react";
import { MdSearch } from "react-icons/md";
import { InputAdornment } from "@mui/material";
import { useFilterContext } from "../../contexts/FilterContext/FilterContext";
import Image from "next/image";
import { OpportunitiesService } from "src/services";
import { CloseIcon } from "src/components/common/icons";

const SearchBar = () => {
    const [opportunities, setOpportunities] = useState<{ suggestion: string }[]>([]);
    const [label, setLabel] = useState("");
    const { searchParams, filterDispatch } = useFilterContext();
    const [searchBarText, setSearchBarText] = useState<string>(searchParams.content);
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const [city, setCity] = useState("");
    const inputRef = useRef(null);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const query = event.target.value;
        setSearchBarText(query);
        if (!query) {
            handleSearchIconClick(query);
            return;
        }
        fetchData(query);
    };

    //  click in the mindkind logo - we also reset the bar input value
    useEffect(() => {
        if (searchParams.content === "") {
            setSearchBarText("");
            setLabel("");
        }
    }, [searchParams.content]);


    const handleListItemClick = (selectedSuggestion: string) => {
        setSearchBarText(selectedSuggestion);
        setLabel(selectedSuggestion);
        setOpportunities([]);
        setIsDropdownVisible(false);
    };

    const handleSearchIconClick = (searchText: string) => {
        filterDispatch({ type: "SET_CONTENT", payload: searchText });
        setOpportunities([]);
        setIsDropdownVisible(false);
    };

    const handleInputFocus = () => {
        setIsDropdownVisible(true);
    };

    const handleInputBlur = () => {
        setTimeout(() => setIsDropdownVisible(false), 200);
    };

    const onKeyDown = (e: any) => {
        if (e.keyCode === 13) {
            handleSearchIconClick(searchBarText);
        }
    };

    useEffect(() => {
        if (label !== "") {
            filterDispatch({ type: "SET_CONTENT", payload: label });
        }
    }, [label, filterDispatch]);

    const fetchData = async (searchText: string) => {
        try {
            const query = {
                searchText,
                minPrice: searchParams.minprice,
                maxPrice: searchParams.maxprice,
                latitude: searchParams.location.lat,
                longitude: searchParams.location.lng,
            };

            if (!searchText) {
                return;
            }
            if (!isDropdownVisible) {
                setIsDropdownVisible(true);
            }

            const fetchedOpportunities = await OpportunitiesService.searchOpportunities(query);
            setOpportunities(fetchedOpportunities);

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        const savedCity = localStorage.getItem("searchedCity");
        if (savedCity) {
            setCity(savedCity);
        } 
        else {
            setCity("");
        }
    }, [localStorage.getItem("searchedCity")]);

    return (
        <div className={"search-bar relative w-full lg:w-[479px]"}>
            <input
                ref={inputRef}
                id='searchbar'
                value={searchBarText}
                placeholder={`Search ${city ? city : 'MindKind'}`}
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                onKeyDown={onKeyDown}
                autoComplete="off"
                className="py-2 pl-7 border border-gray-300 rounded-3xl shadow-lg bg-white focus:outline-none focus:border-yellow-500 w-full"
            />
            {isDropdownVisible && opportunities.length > 0 && (
                <div className="absolute mt-2 w-full bg-white border border-white rounded-lg shadow-lg">
                    {opportunities.map((item, index: number) => (
                        <div
                            className='pointer-events-auto hover:bg-gray-200 w-full cursor-pointer px-2 py-1 flex items-center align-center'
                            key={"suggestion_" + index}
                            onMouseDown={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                            onClick={(e) => {
                                e.stopPropagation();
                                handleListItemClick(item.suggestion);
                            }}>
                            <Image alt='search' src="/arrow_search_bar.svg" width={20} height={20} />
                            <div className="my-2 ml-3">
                                {item.suggestion}
                            </div>
                        </div>
                    ))}
                </div>
            )}
            <InputAdornment
                position="start"
                className="absolute right-[-4px] top-[21px] transform -translate-y-1/2 text-gray-500"
            >
                {searchBarText !== "" && (
                    <CloseIcon
                        size={10}
                        color="#888888"
                        onClick={(e: MouseEvent) => {
                            e.stopPropagation();
                            setSearchBarText(""); 
                            setLabel("");
                            filterDispatch({ type: "SET_CONTENT", payload: "" });
                        }}
                    />
                )}
                <div className='rounded-full hover:bg-gray-200 p-2'>
                    <MdSearch className="text-brand" size={21} onClick={() => handleSearchIconClick(searchBarText)} />
                </div>
            </InputAdornment>
        </div>
    );
};

export default SearchBar;
